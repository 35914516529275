import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import swipeIcon from "../../assets/icons/swipeIcon.svg";
import { colors } from "../../styles/variables";

const BounceAnimation = keyframes`
    from { 
      Transform: translateY(0.5em);
    }
    to { 
      Transform: translateY(1em); 
    }
`;

const Container = styled.div`

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
`;

const Title = styled.div`

  font-size: 10px;
  color: ${colors.white};
`;
interface IconProps {
  flip?: boolean;
}

export const Icon = styled.div<IconProps>`
  display: flex;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  color: white;
  &:after {
    ${(props) => props.flip && "transform: rotate(180deg);"};
    content: "";
    opacity: 0.5;
    background: url(${swipeIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50%;
    width: 50%;
  }

  transform: translateY(1em);
  animation-name: ${BounceAnimation};
  animation-timing-function: ease-in;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

interface ButtonProps {
  parentCallback: () => void;
}

const SwipeUp: React.FC<ButtonProps> = (props) => {
  return (
    <div onClick={props.parentCallback}>
      <Container>
        <Title>
          {/* <h2 style={{
        fontFamily: "Helvetica Neue Thin"
      }}>Swipe Up</h2> */}
        </Title>
        <Icon />
      </Container>
    </div>
  );
};

export default SwipeUp;
