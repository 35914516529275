import React from "react";
import styled from "@emotion/styled/macro";
import { useState } from "react";
import { breakpoints, colors } from "../styles/variables";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import "../index.css";

const Container = styled.div`
  width: 100%;
  position: absolute;
  z-index: 12;
  top: 0;
  left: 0;
  background: ${colors.black};
  height: 100%;
  color: ${colors.white};
`;

const TextContainer = styled.div`
  margin: 10rem 3rem;
  > * {
    text-decoration: none;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 0rem;
`;

const HamburgerLine = styled.div<hamburgerProps>`
  width: 28px;
  height: 1px;
  background-color: white;
  transition: 100ms ease-in;
`;

type hamburgerProps = { active: boolean };

const Hamburger = styled.div<hamburgerProps>`
  margin-bottom: 0px;
  margin-top: 0px;

  width: 28px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  justify-content: space-between;
  z-index: 100000;

  ${HamburgerLine}:nth-child(1) {
    height: 5px;
  }

  ${(p) =>
    p.active
      ? `

      height: 28px;
      ${HamburgerLine}{
        transition: all 100ms ease-in;
      }


      ${HamburgerLine}:nth-child(1) {
        height:1px;
    transform: translateY(14px) rotate(45deg);
  }

  ${HamburgerLine}:nth-child(2) {
    display: none;
  }

  ${HamburgerLine}:nth-child(3) {
    
    transform: translateY(-14px) rotate(-45deg);
    
  }`
      : null}
`;

const Header = styled.h3`
  position: absolute;
  top: 3%;
  left: 5%;
  margin-top: 0px;
  max-width: 90px;
  font-size: 15px;

  line-height: 15px;
  font-family: "GT Sectra Regular";
  font-weight: 100;
  color: ${colors.white};
  &:visited {
    color: ${colors.white};
  }
  @media (min-width: ${breakpoints.md}px) {
    font-size: 2.5rem;
    line-height: 45px;
    max-width: 200px;
  }
`;

const NavLink = styled.h3`
  color: #fff;
  font-size: 2rem;
  font-family: "GT Sectra Regular";
  margin: 0.5rem 0;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 3rem;
  }
`;

type navigationProps = {
  color?: string;
};

const Navbar: React.FC<navigationProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Header style={{}}>línte na farraige</Header>
      <FlexDiv
        css={css`
          justify-content: flex-end;
          align-items: flex-end;
        `}
      >
        <Hamburger
          active={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          style={{ paddingRight: "10px" }}
        >
          <HamburgerLine active={isOpen} style={{ marginLeft: "0px" }} />
          <HamburgerLine active={isOpen} />
          <HamburgerLine active={isOpen} style={{}} />
        </Hamburger>
      </FlexDiv>
      {isOpen && <Menu />}
    </React.Fragment>
  );
};

const Menu: React.FC = () => {
  return (
    <React.Fragment>
      <Container>
        <TextContainer>
          <Link
            css={css`
              display: contents;
            `}
            to="/"
          >
            <Header>línte na farraige</Header>
          </Link>
          <br />
          <a href="https://info.lintenafarraige.ie/our-story.html">
            <NavLink>Our Story</NavLink>
          </a>
          <a href="https://info.lintenafarraige.ie/get-involved.html">
            <NavLink>Get Involved</NavLink>
          </a>
          <a href="/">
            <NavLink>Locations</NavLink>
          </a>
        </TextContainer>
      </Container>
    </React.Fragment>
  );
};

export default Navbar;
