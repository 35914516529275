/**
 *  These are the global colors for the project
 *   [Click here to view colors and keys](https://client.web-space.design/wiki/W4jxbexMoAiPtuT3z/XZjozK5PqmYpDtqHk)
 */
export const colors = {
  black: "#000",
  white: "#fff",
} as const;
/**
 * Keys for the global color variable
 */
export type LinteColorKey = keyof typeof colors;

/**
 * This type allows for color properties to be evaluated against global Color values.
 * These values can be accessed by importing "colors" from 'src/styles/variables'
 *
 *
 *
 */
export type LinteColorVal = typeof colors[LinteColorKey];

export const fonts = {
  sansSerif: `,-apple-system, BlinkMacSystemFont, "Segoe ui", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif`,
  sansSerifAlt: `,-apple-system, BlinkMacSystemFont, "Segoe ui", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif`,
  serif: 'Georgia, "Times New Roman", Times, serif',
} as const;

export const breakpoints = {
  sm: 500,
  md: 900,
  lg: 1600,
} as const;

/**
 * Keys for the global breakpoints variable
 */
export type LinteBreakPointKey = keyof typeof breakpoints;

/**
 * This type allows for color properties to be evaluated against global BreakPoint values.
 *
 *
 * There is also `BreakPointKey` for evaluating the keys of the breakpoint map
 */
export type LinteBreakPointVal = typeof breakpoints[LinteBreakPointKey];

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const dimensions = {
  fontSize: {
    regular: 22,
    large: 24,
    small: 15,
  },
  headingSizes: {
    h1: 2.2,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
    h5: 1.05,
  },
  headingSizesMobile: {
    h1: 2,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  gridContainerPadding: 80,
  gridContainerMobileTabletPadding: 25,
};

export const heights = {
  header: 60,
};
