import React, { useEffect } from "react";
import styled from "@emotion/styled";

import { breakpoints, colors, dimensions } from "../styles/variables";
import Navbar from "../components/Navbar";
import { keyframes } from "@emotion/react";

const RiseFade = keyframes`
0% {
    opacity:0.1;   
    transform:translateY(20%) 
  }
  100% {
    opacity:1;    
    transform:translateY(0%)
  }
`;

const Heading = styled.h1`
  /* font-size: ${dimensions.fontSize.large}px; */
  font-size: 34px;
  background-color: ${colors.black};

  color: ${colors.white};
  margin-left: 20px;
  animation: ${RiseFade} 2s forwards;
  color: ${colors.white};
  animation: ${RiseFade} 2s forwards;
  border: 1px solid ${colors.white};
  box-shadow: -2px 3px 0 -1px ${colors.black}, -2px 3px 0 0 ${colors.white},
    3px -3px 0 -1px ${colors.black}, 3px -3px 0 0 ${colors.white};
  margin: 20px 80px 40px 20px;
  padding: 0.5rem;
  width: max-content;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 5rem;
  }
`;

const OurStory = (): JSX.Element => {
  useEffect(() => {
    document.title = "Our Story | Línte Na Farraige";
  });
  return (
    <React.Fragment>
      <div style={{ overflowY: "hidden" }}>
        <Navbar />
        <Heading>Our Story</Heading>

        {/* Content here */}
      </div>
    </React.Fragment>
  );
};

export default OurStory;
