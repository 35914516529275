import styled from "@emotion/styled";

import SwipeUp from "../../components/global/SwipeUp";
import Navbar from "../../components/Navbar";
import { colors, dimensions, breakpoints } from "../../styles/variables";
import { useEffect, useRef, useState } from "react";
import { Fade, FadeAndSlide, BounceAnimation } from "../../styles/animations";
import InView from "react-intersection-observer";
import hero1 from "../../assets/icons/hero_1.png";
import exam from "../../assets/icons/bg.gif";



const Container = styled.div`
  width: 60%;
  margin: auto;
  height:64%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  animation-name: ${FadeAndSlide};
  animation-duration: 2s;
  animation-direction: linear;
  z-index:50;
`;

const SwipeContainer = styled.div`
 
  height: 8%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  animation-name: ${Fade};
  animation-duration: 8s;
  animation-direction: linear;

  z-index:101;
`;

const MainHeading = styled.h1`
  font-size:50px;
  line-height: 50px;
  color: ${colors.white};
  text-align: center;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 5rem;
  }
  z-index:50;
`;

const StoryElementContain = styled.div`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 5),
      rgba(0, 0, 0, 100)
    ),
    url(${exam});
  background-size: 100% 100%;
    top:0%;
  background-repeat: no-repeat;
  @media (min-width: 500px) {
    background-size: cover;
    z-index:0;
  }
`;

const ParaImage = styled.div`
 
height: 100%;
width: 100%;
background-image:url(${hero1}); 
background-size: 100% 100%;

background-repeat: no-repeat;
@media (min-width: 500px) {
  background-size: cover;
}
position:absolute;
top:0;
z-index:0;
animation-name: ${BounceAnimation};
animation-duration: 2s ;
transition-timing-function: ease-in-out;
transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
animation-iteration-count: infinite;
`;

interface StoryLandingProps {
  parentCallback: (index: number) => void;
  parentVisibilityCallback: (childData: boolean, ref: any) => void;
}

const StoryLanding: React.FC<StoryLandingProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [landingInView, setLandingInView] = useState<boolean>(true);

  useEffect(() => {
    props.parentVisibilityCallback(landingInView, containerRef);
  }, [landingInView, props]);

  return (
    <InView
      style={{
        height: "100%",
        scrollSnapStop: "always",
        scrollSnapAlign: "center",
      }}
      threshold={0.9}
      onChange={(isVisible) => setLandingInView(isVisible)}
    >
      <StoryElementContain ref={containerRef}>
            {/* <div style={{
        height: "100%",
        width: "100%",
        position:'absolute',
        top:'0px',
        zIndex: '1'
      }}><img src={hero1} 
      style={{
        height: "100%",
        width: "100%"
      }}></img></div>

      <div style={{
        height: "100%",
        width: "100%",
        position:'absolute',
        top:'0px',
        zIndex: '2'
      }}><img src={hero2} 
      style={{
        height: "100%",
        width: "100%"
      }}></img></div>

      <div style={{
        height: "100%",
        width: "100%",
        position:'absolute',
        top:'0px',
        zIndex: '3'
      }}><img src={hero3} 
      style={{
        height: "100%",
        width: "100%"
      }}></img></div>

      <div style={{
        height: "100%",
        width: "100%",
        top:'0px',
        zIndex: '4',
        position: 'absolute',
        backgroundAttachment: 'fixed'
      }}><img src={hero4} 
      style={{
        height: "100%",
        width: "100%",
      }}></img></div>  */}

    

      <Container>
        <MainHeading>línte na farraige</MainHeading>
        <SwipeContainer>
        <SwipeUp parentCallback={() => props.parentCallback(0)} />
      </SwipeContainer>
      </Container>
      


    </StoryElementContain>
      
    </InView>
  );
};

export default StoryLanding;
