import { IFlex, IMargin, ISection } from "../../data/sites";
import { breakpoints } from "../../styles/variables";
import styled from "@emotion/styled";
import React from "react";
import InView from "react-intersection-observer";
import { colors, dimensions } from "../../styles/variables";
import { keyframes, css } from "@emotion/react";
import {
  FadeAndSlide,
  Slide,
  Fade,
  BounceAnimation,
} from "../../styles/animations";


interface TextInView {
  fadeIn: boolean;
  margins?: IMargin;
  flex?: IFlex;
}

const whichAnimation = (prop: string) => {
  switch (prop) {
    case "FadeAndSlide":
      return FadeAndSlide;
    case "Slide":
      return Slide;
    case "Fade":
      return Fade;
    case "BounceAnimation":
      return BounceAnimation;
  }
};

const MainHeading = styled.h1<TextInView>`
  font-size: ${dimensions.headingSizes.h1}rem;
  color: white;
  animation: linear ${(props) => (props.fadeIn ? Fade : "")} 3s forwards;
  width: ${(props) =>
    props.margins?.sm.titleWidth ? props.margins?.sm.titleWidth + "%" : "85%"};
  margin-top: ${(props) =>
    props.margins?.sm.titleMargin
      ? `${props.margins?.sm.titleMargin}px`
      : "unset"};

  opacity: 100;
  /* transition: opacity 1000ms ease; */
  z-index: 10;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 5rem;
    text-align: right;
    margin-top: ${props => props.margins?.md.titleMargin}px;
  }
`;

const MainCopy = styled.p<TextInView>`
  position: relative;
  z-index: 9;
  font-size: ${dimensions.fontSize.small}px;
  color: ${colors.white};
  width: 75%;

  transform: ${(props) =>
    props.margins?.sm.textLeftMargin
      ? `translateX(${props.margins?.sm.textLeftMargin}px)`
      : "unset"};

  margin-bottom: ${(props) =>
    props.margins?.sm.textMargin
      ? `${props.margins?.sm.textMargin}px`
      : "unset"};

  opacity: 100;
  animation: linear ${(props) => (props.fadeIn ? Fade : "")} 3s forwards;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 1.8rem;
    text-align: right;
    margin-bottom: ${props => props.margins?.md.textMargin}px;

  }

`;

const fromTo = (imagePositions: Array<number>, from: boolean, size: string) => {
  let concatPositions = "";

  let slice1: number = from ? 0 : 2;
  let slice2: number = from ? 2 : 4;

  switch (size) {
    case "sm":
      concatPositions += `${imagePositions
        .slice(slice1, slice2)
        .join("px ")}px,`;
      break;
    case "md":
      concatPositions += `${imagePositions
        .slice(slice1, slice2)
        .join("px ")}px,`;
      break;
    case "lg":
      concatPositions += `${imagePositions
        .slice(slice1, slice2)
        .join("px ")}px,`;
      break;
  }
  return concatPositions.slice(0, concatPositions.length - 1);
};

const animatedBackground = (position: number[], size: string) => keyframes`
  from {
    background-position: ${fromTo(position, true, size)}
  }
  to {
    background-position: ${fromTo(position, false, size)}
  }
  `;

interface StoryElementContainProps {
  visible: boolean;
}

const StoryElementContain = styled.div<StoryElementContainProps>`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  animation: linear ${(props) => (props.visible ? Fade : "")} 1s forwards;
  overflow: hidden;
  z-index: 0;
  height: 100%;
  background-size: cover;
  background-repeat: repeat-x;
  width: 100%;
  background-attachment: fixed;
  position: relative;

  @media (min-width: ${breakpoints.lg}px) {
    width: 90%;
  }
`;

const SectionContain = styled.div`
  height: 100%;
  
`;

// controls both text boxes size? need to vary
const TextContain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 95%; 
  margin: auto;
  @media (min-width: ${breakpoints.lg}px) {
    width: 80%;
    padding-left:300px;
  }
  @media (min-width: ${breakpoints.md}px) {    
    width: 55%;
    align-items: flex-end;
    margin: 0 120px 0 auto;
  }
  padding-left:18px;
`;

const ImageContainer = styled.div<ImageContainerProps>`

background-image: url("${(props) => props.url}");
  
 display:  ${(props) => (props.isVisible ? "inherit" : "none")};
  background-size: cover;
  width: 100%;



  @media (min-width: ${breakpoints.md}px) {
    
    max-width: 30%;
    
  }
  height: 100%;
  position: absolute;
  z-index: ${(props) => props.zIndex};

  animation-timing-function: ease-in;
  animation-duration: ${(props) => props.duration}s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 100%
  background-attachment: fixed;

  background-repeat: repeat-x;

  animation-name: ${(props) =>
    props.animation !== "Positional"
      ? css`
          ${whichAnimation(props.animation)}
        `
      : css`
          ${animatedBackground(props.position, "sm")}
        `};
`;

// const SwipeContainer = styled.div`
//   height: 20%;
//   display: flex;
//   flex-direction: column;
//   justify-content: end;
//   animation-name: ${Fade};
//   animation-duration: 8s;
//   animation-direction: linear;
// `;

interface FlexAttributes {
  flex?: IFlex;
  heading?: boolean;
}

const FlexContainer = styled.div<FlexAttributes>`
  display: flex;

  
  justify-content: ${(props) =>
    props.flex !== undefined
      ? props.heading
        ? props.flex.heading.justify
        : props.flex.body.justify
      : ""};

  align-items: ${(props) =>
    props.flex !== undefined
      ? props.heading
        ? props.flex.heading.align
        : props.flex.body.align
      : ""};

  height: 100%;

  @media (min-width: ${breakpoints.md}px) {
    justify-content: end;
  }
`;

interface ImageContainerProps {
  url: string;
  position: number[];
  zIndex: number;
  animation: string;
  duration: number;
  isVisible: boolean;
}

interface IObserver {
  id: number;
  isVisible: boolean;
  // ref?: React.MutableRefObject<HTMLDivElement | undefined>;
  ref?: any;
}
interface StoryProps {
  parentCallback: (childData: IObserver[]) => void;
  stories: ISection[];
}

const Story: React.FC<StoryProps> = (props) => {
  // useState starts as empty array of observers
  const [intersectionObserverEntries, setIntersectionObserverEntries] =
    React.useState<IObserver[]>([]);

  const refsArray = React.useRef<Array<HTMLDivElement | null>>([]);

  // watch the observers and the number of stories
  React.useEffect(() => {
    // only do this the on page load
    if (intersectionObserverEntries.length === 0) {
      let observers: IObserver[] = [];

      refsArray.current = refsArray.current.slice(0, props.stories.length);

      // let refs: any = [];
      // refs.current = Array(props.stories.length).fill().map((_,i) => refs.current[i] || createRef());

      for (let i = 0; i < props.stories.length; i++) {
        // refs.push(createRef());

        observers.push({
          // pushing their default values
          id: i,
          isVisible: false,
          ref: refsArray.current[i],
          // ref: i === 0 ? myRef : undefined,
        });
      }

      setIntersectionObserverEntries(observers);
    }
  }, [intersectionObserverEntries, props.stories.length]);

  // when the value of inView changes for a story, we run this, passing story index and visibility
  const handleInViewChange = (index: number, isVisible: boolean) => {
    setIntersectionObserverEntries((old) => {
      // mapping new values to each observer object
      old.forEach((item) => {
        // if index of this particular observer matches the one passed to the function, change its visibility value
        if (item.id === index) item.isVisible = isVisible;
        // otherwise leave it as is
        else item.isVisible = false;
      });
      // spread operator returning the one we've changed, plus all the existing values
      return [...old];
    });

    props.parentCallback(intersectionObserverEntries);
  };

  return (
    <>
      {props.stories.map((story, storyIndex) => (
        <InView
          style={{}}
          key={storyIndex}
          threshold={0.95}
          root={document.body}
          onChange={(isVisible) => handleInViewChange(storyIndex, isVisible)}
        >
          {({ inView, ref }) => (
            <div
              style={{
                height: "100%",
                scrollSnapStop: "always",
                scrollSnapAlign: "center",
              }}
              key={storyIndex}
              // ref={storyIndex === 0 ? myRef : ref}
              // ref={refsArray.current[storyIndex]}
              ref={(el) => (refsArray.current[storyIndex] = el)}
            >
              <StoryElementContain
                visible={
                  intersectionObserverEntries.findIndex(
                    (entry) => entry.isVisible
                  ) === storyIndex
                    ? true
                    : false
                }
                ref={ref}
              >
                {story.image.map((image, i) => {
                  return (
                    <ImageContainer
                      isVisible={
                        intersectionObserverEntries.findIndex(
                          (entry) => entry.isVisible
                        ) === storyIndex
                          ? true
                          : false
                      }
                      key={i}
                      url={image.url}
                      position={image.sm.position}
                      zIndex={story.image.length + 1 - image.layer}
                      animation={image.animation}
                      duration={image.duration}
                    />
                  );
                })}
                <>
                  <SectionContain>
                    <TextContain>
                      <FlexContainer flex={story.flex} heading={true}>
                        <MainHeading
                          id="mainHeading"
                          fadeIn={inView}
                          margins={story.margins}
                          style={{
                            // marginTop: '-200px'
                          }}
                        >
                          {story.title}
                        </MainHeading>
                      </FlexContainer>
                      <FlexContainer flex={story.flex} heading={false}>
                        <MainCopy fadeIn={inView} margins={story.margins}
                        style={{
                          textShadow: "1px 1px 2px rgba(0,0,0)"
                        }}
                        >
                          {story.content}
                        </MainCopy>

                        {/* <SwipeContainer>
                          <Icon />
                        </SwipeContainer> */}
                      </FlexContainer>
                    </TextContain>
                  </SectionContain>
                </>
              </StoryElementContain>
            </div>
          )}
        </InView>
      ))}
    </>
  );
};

export default Story;
