import styled from "@emotion/styled";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./routing/MainRouter";
import "./index.css";
import IMAGES from "./components/Images"

const Container = styled.div`
  background: black;
  height: 100%;
  margin: 0;
  padding: 0;
  margin: auto;
  scroll-behavior: smooth;
`;

/**
 * Entry point for the application
 *
 * @returns {JSX.Element}
 */
const App = (): JSX.Element => {
   document.body.style.backgroundColor = "black";
  
  document.body.style.margin = "0";
  return (
    <Container>
      <Router>
        <MainRouter />
      </Router>
    </Container>
  );
};

export default App;
