import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import sites from "../data/sites.json";
import placeholder1 from "../assets/icons/martello-tower.svg";
import placeholder2 from "../assets/icons/spanish-arch.svg";
import placeholder3 from "../assets/icons/eisenhower-statue.svg";
import placeholder4 from "../assets/icons/balbriggan.png";
import placeholder5 from "../assets/icons/bg.gif";
import { breakpoints, colors, dimensions } from "../styles/variables";
import Navbar from "../components/Navbar";
import { keyframes } from "@emotion/react";


const imageOptions = [placeholder1, placeholder2, placeholder3, placeholder4,placeholder5];

const chooseImage = (index: number) => {
  return imageOptions[index];
};

const RiseFade = keyframes`
0% {
    opacity:0.1;   
    transform:translateY(20%) 
  }
  100% {
    opacity:1;    
    transform:translateY(0%)
  }
`;

const Rise = keyframes`
0% {    
    transform:translateY(20%) 
  }
  100% {    
    transform:translateY(0%)
  }
`;

const Fade = keyframes`
0% {    
    opacity:0.1;
  }
  100% {    
    opacity:1;
  }
`;

const Bg = styled.h1`
  background-image: "../assets/icons/background.png";
  z-index: 500;
;`;

const Heading = styled.h1`
  /* font-size: ${dimensions.fontSize.large}px; */
  font-size: 24px;
  background-color: ${colors.black};
  color: ${colors.white};
  animation: ${Fade} 2s forwards;
  color: ${colors.white};
  animation: ${Fade} 2s forwards;
  border: 1px solid ${colors.white};
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: max-content;
  border-top: 8px solid ${colors.white};
  font-size: 34px;
  color: ${colors.white};
  margin-top: 45px;


  @media (min-width: ${breakpoints.md}px) {
    font-size: 5rem;
  }
`;

const LineBreak = styled.hr`
  border-top: 2px solid white;
  margin: -25px 0px 0px 0px;
  animation: ${Fade} 2s forwards;
  animation: ${RiseFade} 2s forwards;
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 40px 0px 20px;
  padding: 1rem 0;
  border: 1px solid ${colors.white};
  box-shadow: 
    3px -3px 0 -1px ${colors.black}, 3px -3px 0 0 ${colors.white};
  margin: 20px 80px 20px 20px;
  width:90%;
`;

const IconContainer = styled.div`
  height: 80px;
  width: 80px;
  animation: ${Fade} 3s forwards;
  opacity: 0;
margin-left: 20px;
  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 100%;
    width: 9rem;
  }
`;

const Container = styled.div`
  /* max-width: 800px;
  margin: 50px auto; */

  max-height: 70vh;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.md}px) {
    max-height: 500px;
  }
  @media (min-width: ${breakpoints.lg}px) {
    max-height: 700px;
  }

`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 80%;
  animation: ${Rise} 2s forwards;

  h3,
  h4 {
    margin-left: 20px;
  }

  @media (min-width: ${breakpoints.md}px) {
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2rem;
    }
  }
`;

const Title = styled.h3`
  /* font-size: ${dimensions.headingSizes.h3}px; */
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: ${colors.white};
`;

const Location = styled.h4`
  /* font-size: ${dimensions.headingSizes.h4}px; */
  font-size: 14px;
  margin-top: 0px;
  color: ${colors.white};
  opacity: 70%;
`;

//redirect for sites available
function test(x: string){
  if(x !== '/site/spanish-arch' && x !== '/site/wexford-harbour'){
    document.location.href = x;
  };
  document.body.style.overflowY = 'auto';
}
const Landing = (): JSX.Element => {
  useEffect(() => {
    document.title = "Select a Location | Línte Na Farraige";
  });

  return (
    <React.Fragment>
      <Bg>
      <Navbar />
      <div style={{ overflowY: "hidden", backgroundImage: "../assets/icons/background.png" }}>
        <div style={{ display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
        <Heading>Select a Location</Heading>
        </div>
        <LineBreak/>
        <Container>
          {sites.map((site) => (
            <Link
              key={`${site.url}`}
              to={`${site.url}`}
              style={{ textDecoration: "none" }}
              onClick={() => test(site.url)}>
              {/* <LineBreak /> */}
              <LocationContainer>
                <IconContainer>
                  <img src={`${chooseImage(site.id - 1)}`} alt="boot" />
                </IconContainer>
                <TextContainer>
                  <Title> {site.name}</Title>
                  <Location> {site.location}</Location>
                </TextContainer>
              </LocationContainer>
            </Link>
          ))}
        </Container>
      </div>
      </Bg>
    </React.Fragment>
  );
};

export default Landing;
