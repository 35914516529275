import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { colors } from "../styles/variables";
import { IWaterLevel } from "../pages/Home";
import CountUp from "react-countup";
import { breakpoints } from "../styles/variables";
import { Icon } from "./global/SwipeUp";
import { Fade } from "../styles/animations";

const FlickerAnimation = keyframes`
0% {
    opacity:0.1;
  }
  2% {
    opacity:1;
  }
  4% {
    opacity:0.1;
  }
  
  8% {
    opacity:1;
  }
  70% {
    opacity:0.7;
  }
  100% {
    opacity:1;
  }
`;

const Flash = keyframes`
0% {
  
  background-color: rgba(255, 255, 255,0);
  color:rgba(255,255,255,1);
  box-shadow: none;
  }
  60% {
    background-color: rgba(255, 255, 255,0);
  color:rgba(255,255,255,1);
  box-shadow: none;
    }

75% {
  -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  box-shadow: 0px 0px 78px 15px rgba(16, 134, 232, 0.73);
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.73);
  background-color: rgba(255, 255, 255,.75);
  color:rgba(0,0,0,1);
  border-radius:90px;
  }


  100% {
  
  background-color: rgba(255, 255, 255,0);
  color:rgba(255,255,255,1);
  }
`;

const FlickerLine = styled.div`
  text-align: center;
  filter: blur(0px);
  border: 1px solid #fff;
  padding-top: 0rem;
  width: 100vw;
  background: #fff;
  animation: ${FlickerAnimation} 2s linear infinite;
  -webkit-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  -moz-box-shadow: 0px 0px 78px 4px rgba(16, 134, 232, 0.73);
  box-shadow: 0px 0px 78px 15px rgba(16, 134, 232, 0.73);
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.73);
`;



const FlickerContain = styled.div<FlickerProps>`
  pointer-events: none;
  margin: 2rem 0;
  position: fixed;
  bottom: 10%;
  transition: transform 1.3s;
  transition-timing-function: cubic-bezier(.48,.24,.28,.99);
  transform: translateY(${({ translateValue }) => translateValue}vh);
  visibility: ${(props) => (props.inView ? "visible" : "hidden")};

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    justify-content: center;
  }
`;

interface FlickerProps {
  translateValue: number;
  inView: boolean;
}

const Container = styled.div`
  font-family: "Helvetica Neue Thin";
  font-size: 12px;
  color: ${colors.white};
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  margin-top: -7px;

  @media (min-width: ${breakpoints.md}px) {
    margin: auto;
    width: 80%;
  }
`;

const YearContainer = styled.div`
  margin: 20px;
  text-align: center;
  display: inline-block
`;

const WaterContainer = styled.div`
  margin: 20px;
  text-align: center;
  display: inline-block
`;

const Title = styled.div<VisibleProps>`
  opacity: 50%;
  visibility: ${(props) => (props.inView ? "visible" : "hidden")};
  @media (min-width: ${breakpoints.md}px) {
    margin: auto;
    width: 100%;
    font-size: 1.5rem;
  }
`;

interface VisibleProps {
  inView: boolean;
}

// const Text = styled.div<VisibleProps>`
//   visibility: ${(props) => (props.inView ? "visible" : "hidden")};
// `;

var CountContainer = styled.div<VisibleProps>`
  visibility: ${(props) => (props.inView ? "visible" : "hidden")};
  @media (min-width: ${breakpoints.md}px) {
    font-size: 1.5rem;
  }
  display:inline-block;
`;

interface WaterLevelProps {
  visibleId: number;
  details: IWaterLevel;
  handleScroll: (index: number) => void;
}

const WaterLevel: React.FC<WaterLevelProps> = (props) => {
  const [prev, setPrev] = useState<number>(props.details.waterLevel);
  const prevWaterLevel = useRef(props.details.waterLevel);

  const [year, setYear] = useState<number>(props.details.year);
  const prevYear = useRef(props.details.year);

  useEffect(() => {

    CountContainer = styled.div<VisibleProps>`
    visibility: ${(props) => (props.inView ? "visible" : "hidden")};
    @media (min-width: ${breakpoints.md}px) {
      font-size: 1.5rem;
    }
    animation: ${Flash} 2.0s ease;
    display:inline-block;
  `;
  
    if (
      prevWaterLevel.current &&
      props.details.waterLevel !== prevWaterLevel.current
    )
      setPrev(prevWaterLevel.current);


    if (prevYear.current && props.details.year !== prevYear.current){

      setYear(prevYear.current);
      
    }
  }, [props.details.waterLevel, props.details.year]);

  useEffect(() => {
    prevWaterLevel.current = props.details.waterLevel;
    prevYear.current = props.details.year;

  }, [props.details.waterLevel, props.details.year]);

  return (
    <React.Fragment>
      <FlickerContain
        translateValue={props.details.barLevel}
        inView={props.details.lineVisible}
      >
        <Container>
          <YearContainer>
            <Title
              inView={props.visibleId !== -1 && props.details.markersVisible}
            >
              Year
            </Title>
            {
              <CountContainer
                inView={props.visibleId !== -1 && props.details.markersVisible}
              >
                <CountUp
                  start={year}
                  end={props.details.year}
                  decimals={0}
                  duration={1.3}
                  preserveValue={true}
                  delay={0}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              </CountContainer>
            }
          </YearContainer>
          <WaterContainer>
            <Title
              inView={props.visibleId !== -1 && props.details.markersVisible}
            >
              Water Level
            </Title>
            {
              <CountContainer
                inView={props.visibleId !== -1 && props.details.markersVisible}
              >
                <CountUp
                  start={prev}
                  end={props.details.waterLevel}
                  decimals={2}
                  duration={1.3}
                  preserveValue={true}
                  delay={0}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />m
                    </div>
                  )}
                </CountUp>
              </CountContainer>
            }
          </WaterContainer>
        </Container>
        <FlickerLine />
      </FlickerContain>
    </React.Fragment>
  );
};

export default WaterLevel;
