import React, { Suspense } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
// import ReactGA from 'react-ga';

import Loading from "../components/global/Loading";
import Landing from "../pages/Landing";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import OurStory from "../pages/OurStory";
import About from "../pages/About";
/**
 * Handles Routing for the application
 *
 * @returns {JSX.Element}
 */
const MainRouter = (): JSX.Element => {
  /**
   * Initialize Google Analytics
   *
   * https://github.com/react-ga/react-ga
   */
  //  ReactGA.initialize(config.ga_id);

  return (
    <React.Fragment>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Landing />} />
          
          <Route path="/site/:siteId" element={<Home />} />

          <Route path="/our-story" element={<OurStory />} />
          <Route path="/get-involved" element={<About />} /> 
          {/* <Link to="/our-story">click me</Link> */}
          {/* <Link to="/site/spanish-arch">Choose your site</Link> */}

          {/* <Route path="/our-story">
            <Navigate to="/lnf/our-story.html"/>
          </Route> */}
          
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default MainRouter;
