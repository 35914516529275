import styled from "@emotion/styled";
import twitter from "../../assets/icons/twitter.svg";
import ig from "../../assets/icons/insta.png";
import ci from "../../assets/icons/bannerEd.png";
import { colors, dimensions } from "../../styles/variables";
import Button from "../Button";
import InView, { useInView } from "react-intersection-observer";
import { Fade, FadeAndSlide } from "../../styles/animations";
import { breakpoints } from "../../styles/variables";
import React from "react";

import { Icon } from "../global/SwipeUp";

interface TextInView {
  fadeIn: boolean;
}

const socials = [twitter, ig];

const Heading2 = styled.h2<TextInView>`
  max-width: 72%;
  font-size: 40px;
  color: ${colors.white};
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0;
  animation: linear ${(props) => (props.fadeIn ? FadeAndSlide : "")} 1s forwards;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 5rem;
    margin-top: 0;
  }
`;

const Heading3 = styled.h3<TextInView>`
  opacity: 0;
  font-size: ${dimensions.headingSizes.h3}rem;
  color: ${colors.white};
  margin-left: 20px;
  margin-bottom: -20px;
  animation: linear ${(props) => (props.fadeIn ? FadeAndSlide : "")} 1s forwards;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 3rem;
  }
`;

const Heading4 = styled.h4<TextInView>`
  opacity: 0;
  font-size: ${dimensions.headingSizes.h4}rem;
  color: ${colors.white};
  margin-left: 20px;
  margin-bottom: 5px;
  animation: linear ${(props) => (props.fadeIn ? FadeAndSlide : "")} 1s forwards;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 1.8rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0;
`;

const SocialContainer = styled.div<TextInView>`
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  opacity: 0;
  animation: linear ${(props) => (props.fadeIn ? Fade : "")} 1s forwards;
`;

const SocialIcon = styled.div`
  margin-right: 20px;

  .img {
    height: 100px;
    width: 100px;
  }
`;

const Cilogo = styled.div`
  margin-left: -0px;
  margin-top: 15px;
  padding-right: 20px;

  .img {
    width: 60%;
  }

  @media (min-width: ${breakpoints.md}px) {
    margin-left: -60px;
    .img {
      width: 0%;
    }
  }
`;

const StoryElementContain = styled.div<TextInView>`
  animation: linear ${(props) => (props.fadeIn ? Fade : "")} 1s forwards;
  max-height: 100%;
  width: 100%;

  padding-top: 20px;
  @media (min-width: ${breakpoints.md}px) {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
  }
`;

interface VisibleProps {
  inView: boolean;
}

const SwipeContainer = styled.div<VisibleProps>`
  visibility: ${(props) => (props.inView ? "visible" : "hidden")};
  transition: 0.2s ease;
  z-index: 11;
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;

  & div {
    margin: 20px 0;

    transition: opacity 0.5s;
    opacity: ${(props) => (props.inView ? 1 : 0)};
  }
`;

interface CTAProps {
  parentCallback: (childData: boolean) => void;
  backUp: () => void;
}

const CallToAction: React.FC<CTAProps> = (props) => {
  const [ctaVisible, setCtaVisible] = React.useState<boolean>(false);
  // const footerRef = React.useRef(null);
  React.useEffect(() => {
    props.parentCallback(ctaVisible);
  }, [ctaVisible, props]);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.9,
    root: document.body,
  });

  return (
    <InView
      style={{
        paddingLeft: "20px",
        height: "100%",
        scrollSnapStop: "always",
        scrollSnapAlign: "center",
      }}
      threshold={0.3}
      onChange={(isVisible) => setCtaVisible(isVisible)}
    >
      <StoryElementContain ref={ref} fadeIn={inView}>
        {/* {inView && <Navbar />} */}

        <Heading2 fadeIn={inView}>Join us on Our Journey</Heading2>
        <ButtonContainer>
          <a href="https://info.lintenafarraige.ie/our-story.html">
            <Button fadeIn={inView} text={"Our Story"} />
          </a>
          <a href="https://info.lintenafarraige.ie/get-involved.html">
            <Button fadeIn={inView} text={"Get Involved"} />
          </a>
        </ButtonContainer>
        <Heading3 fadeIn={inView}>Find Out More</Heading3>
        <Heading4 fadeIn={inView}>What can I do?</Heading4>
        <ButtonContainer>
          <a href="https://www.surveymonkey.com/r/ZBZ8GTS">
            <Button fadeIn={inView} text={"Complete Our Survey"} />
          </a>
          <a href="/">
            <Button fadeIn={inView} text={"Home"} />
          </a>
        </ButtonContainer>
        <Heading3 fadeIn={inView}>Follow us</Heading3>
        <Heading4 fadeIn={inView}>Help us spread the word</Heading4>
        <SocialContainer fadeIn={inView}>
          {/* {socials.map((social) => {
            return ( */}
          <SocialIcon>
            <a href="https://twitter.com/lintenafarraige">
              <img width="25" height="25" alt={socials[0]} src={socials[0]} />
            </a>
          </SocialIcon>
          <SocialIcon>
            <a href="https://www.instagram.com/lintenafarraige/">
              <img width="25" height="25" alt={socials[1]} src={socials[1]} />
            </a>
          </SocialIcon>
          {/* );
          })} */}
        </SocialContainer>
        <Cilogo>
          <img width="100%" height="100%" src={ci}></img>
        </Cilogo>
      </StoryElementContain>
      <SwipeContainer inView={inView}>
        <Icon flip onClick={props.backUp} />
      </SwipeContainer>
    </InView>
  );
};

export default CallToAction;
