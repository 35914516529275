import { keyframes } from "@emotion/react";

export const Fade = keyframes`
  0% {
    opacity:0.0;
    }
  100% {
    opacity:1;  
  }
`;

export const Slide = keyframes`
  0% {     
    /* padding-bottom:50px; */
    transform: translateY(-50px)
    }
  100% {   
    /* padding-bottom:0px; */
    transform: translateY(0)

  }
`;

export const FadeAndSlide = keyframes`
  0% {
    /* padding-top:100px; */
    transform: translateY(100px) ;
    opacity:0.0;     
    }
  100% {
    /* padding-top:0px; */
    transform: translateY(0) ;
    opacity:1;  


  }
`;

export const BounceAnimation = keyframes`
from { 
  transform: translateY(0.5em);
}
to { 
  transform: translateY(1em); 
}
`;
