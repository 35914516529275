import styled from "@emotion/styled";
import CallToAction from "../components/Story/CallToAction";
import Story from "../components/Story/Story";
import StoryLanding from "../components/Story/StoryLanding";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import sites from "../data/sites.json";
import WaterLevel from "../components/WaterLevel";
import "../styles/fonts.css";
import { breakpoints, colors } from "../styles/variables";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Icon } from "../components/global/SwipeUp";
import e from "express";

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  @media (min-width: ${breakpoints.md}px) {
    overflow-y: hidden;
  }
`;

const RefHolder = styled.div`
  display: contents;
  height: 100%;
`;
export interface IObserver {
  id: number;
  isVisible: boolean;
  // ref?: React.MutableRefObject<HTMLDivElement | undefined>;
  ref?: any;
}
export interface IWaterLevel {
  lineVisible: boolean;
  markersVisible: boolean;
  barLevel: number;
  barLevel2: number;
  waterLevel: number;
  year: number;
}

const DotsContainer = styled.div`
  display: inline-block;
  justify-content: left;
  width: 100%;
  align-items: center;
  position: absolute;
  top: 0.8rem;
  right: 0.4rem;
  direction: rtl;
`;

interface DotProps {
  isOpaque: boolean;
}

const Dot = styled.div<DotProps>`
  filter: ${({ isOpaque }) => (isOpaque ? "invert(0)" : "invert(50%)")};
  font-size: 0.8rem;
  margin: 0.2rem 0.5rem;
  background: ${colors.white};
  box-shadow: 0px 0px 10px 0.2px ${colors.white};
  width: 6px;
  height: 6px;
  cursor: pointer;
  /* &:before {
    content: "⬜";
    background: white;
  }   */
`;

interface VisibleProps {
  inView: boolean;
}

const SwipeContainer = styled.div<VisibleProps>`
  /* visibility: ${(props) => (props.inView ? "visible" : "hidden")}; */
  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
  transition: 0.2s ease;
  z-index: 11;
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: end;

  & div {
    margin: 20px 0;

    transition: opacity 0.5s;
    opacity: ${(props) => (props.inView ? 1 : 0)};
  }
`;

const Home: React.FC = () => {
  // const navigate = useNavigate();

  // Initial value for useState, will be filled by data when retrieved
  let siteId: string = useParams().siteId!;
  //.split('/')[2]
  const currentSiteI = sites.findIndex((site) => site.url.split('/')[2] === siteId);

  const currentSite = sites[currentSiteI];

  const [waterLevelDetails, setWaterLevelDetails] = useState<IWaterLevel>({
    lineVisible: true,
    markersVisible: true,
    barLevel: 4,
    barLevel2: -43,
    waterLevel: currentSite?.sections[0].waterLevel,
    year: 2022,
  });

  const [landingVisible, setLandingVisible] = useState<boolean>(true);
  const [ctaVisible, setCtaVisible] = useState<boolean>(false);
  const [visibleStory, setVisibleStory] = useState(Array<IObserver>());

  let storyRef: any = useRef(null);
  let landingRef: any = useRef(null);
  const footerRef: any = useRef(null);

  const handleCallback = (childData: SetStateAction<IObserver[]>) =>
    setVisibleStory(childData);
  const handleCtaCallback = (childData: boolean) => {
    setCtaVisible(childData);
  };
  const handleLandingVisibilityCallback = (childData: boolean, ref: any) => {
    landingRef = ref;
    setLandingVisible(childData);
  };
  const handleLandingCallback = (index: number) => {
    if (storyRef !== null && index === -1) {
      landingRef?.current?.scrollIntoView({ behavior: "smooth" });
    } else if (index >= visibleStory.length) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (visibleStory.length > 0 && visibleStory[index].ref) {
      visibleStory[index].ref?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [visibleId, setVisibleId] = useState<number>(-2);

  useEffect(() => {
    setTimeout(() => {
      if (visibleStory)
        setVisibleId(visibleStory.findIndex((story) => story.isVisible));
    }, 800);
  }, [visibleStory]);

  useEffect(() => {
    if (landingVisible && !ctaVisible) {
      document.title = `${currentSite?.name} | Línte Na Farraige`;

      setWaterLevelDetails({
        lineVisible: true,
        markersVisible: false,
        waterLevel: currentSite?.sections[0].waterLevel,
        year: 0,
        barLevel: 4,
        barLevel2: -43
      });
    } else if (!landingVisible && ctaVisible) {
      // change only a subset of values
      setWaterLevelDetails({
        ...waterLevelDetails,
        barLevel: -5,
        lineVisible: true,
        markersVisible: true,
      });
    }
    // otherwise, must be on a story. show both.
    else {
      setWaterLevelDetails({
        ...waterLevelDetails,
        lineVisible: true,
        markersVisible: true,
      });
      if (visibleStory) {
        if (visibleStory.findIndex((story) => story.isVisible) !== -1) {
          let newDetails =
            currentSite?.sections[
              visibleStory.findIndex((story) => story.isVisible)
            ];
            if(window.screen.width>breakpoints.md){
              console.log('md');
              setWaterLevelDetails({
                ...waterLevelDetails,
                waterLevel: newDetails.waterLevel,
                year: newDetails.year,
                barLevel: newDetails.barLevel2,
              });
            }else{
              console.log('sm');
              setWaterLevelDetails({
                ...waterLevelDetails,
                waterLevel: newDetails.waterLevel,
                year: newDetails.year,
                barLevel: newDetails.barLevel,
              });
            };
          
        }
      }
    }
  }, [landingVisible, ctaVisible, visibleStory, currentSite?.sections]);

  const renderVisibleStories = () => {
    let dots: EmotionJSX.Element[] = [];
    for (let i = 0; i < visibleStory.length; i++) {
      if (!ctaVisible && !landingVisible) {
        dots.push(
          <Dot
            onClick={() => handleLandingCallback(i)}
            key={i}
            isOpaque={i <= visibleId ? true : false}
          />
        );
      }
    }
    return dots;
  };

  if (currentSiteI === -1) {
    return <Navigate to={"/404"} />;
  }

  return (
    <Wrapper>
      <div
        style={{
          height: "100%",
        }}
      >
        <StoryLanding
          parentCallback={handleLandingCallback}
          parentVisibilityCallback={handleLandingVisibilityCallback}
        />
      </div>
      <RefHolder ref={storyRef}>
        <Story
          parentCallback={handleCallback}
          stories={currentSite?.sections}
        />
      </RefHolder>
      <div style={{ height: "100%" }} ref={footerRef}>
        <CallToAction
          parentCallback={handleCtaCallback}
          backUp={() => handleLandingCallback(currentSite.sections.length - 7)}
        />
      </div>
      {!ctaVisible ? (
        <WaterLevel
          visibleId={visibleId}
          details={waterLevelDetails}
          handleScroll={handleLandingCallback}
        />
      ) : (
        ""
      )}

      <DotsContainer>{renderVisibleStories()}</DotsContainer>

      <SwipeContainer
        inView={visibleId !== -1 && !ctaVisible && !landingVisible}
      >
        <Icon flip onClick={() => handleLandingCallback(visibleId - 1)} />
        <Icon onClick={() => handleLandingCallback(visibleId + 1)} />
      </SwipeContainer>
    </Wrapper>
  );
};

export default Home;
