import React from "react";
import styled from "@emotion/styled";
import { breakpoints, colors } from "../styles/variables";
import { Fade } from "../styles/animations";

interface TextInView {
  fadeIn: boolean;
}

const MyButton = styled.button<TextInView>`
  visibility: ${(props) => (props.fadeIn ? "visible" : "hidden")};
  animation: linear ${(props) => (props.fadeIn ? Fade : "")} 3s forwards;
  border-style: solid;
  border-width: 1px;
  color: ${colors.white};
  border-color: ${colors.white};
  background-color: transparent;
  box-shadow:
    3px -3px 0 -1px ${colors.black}, 3px -3px 0 0 ${colors.white};
  font-size: 14px;
  line-height: 32px;

  &:hover {
    background-color: white;
    color: black;
    cursor: pointer;
  }

  margin: 1rem;
  padding: 0.25rem 0.8rem;

  font-family: "Helvetica Neue Thin";

  @media (min-width: ${breakpoints.md}px) {
    font-size: 1.5rem;
    padding: 0.6rem 1.4rem;
  }
`;

interface Text {
  text: String;
  fadeIn: boolean;
}

/**
 * Button Component
 *
 * @returns {JSX.Element}
 */
const Button: React.FC<Text> = (props) => {
  return (
    <React.Fragment>
      <MyButton fadeIn={props.fadeIn}>{props.text}</MyButton>
    </React.Fragment>
  );
};

export default Button;
