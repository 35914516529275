import React, { useEffect } from "react";
import styled from "@emotion/styled";

import { breakpoints, colors, dimensions } from "../styles/variables";
import Navbar from "../components/Navbar";
import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";

const RiseFade = keyframes`
0% {
    opacity:0.1;   
    transform:translateY(20%) 
  }
  100% {
    opacity:1;    
    transform:translateY(0%)
  }
`;

const Rise = keyframes`
0% {    
    transform:translateY(20%) 
  }
  100% {    
    transform:translateY(0%)
  }
`;

const Heading = styled.h1`
  /* font-size: ${dimensions.fontSize.large}px; */
  font-size: 34px;
  background-color: ${colors.black};

  color: ${colors.white};
  margin-left: 20px;
  animation: ${RiseFade} 2s forwards;
  color: ${colors.white};
  animation: ${RiseFade} 2s forwards;
  border: 1px solid ${colors.white};
  box-shadow: -2px 3px 0 -1px ${colors.black}, -2px 3px 0 0 ${colors.white},
    3px -3px 0 -1px ${colors.black}, 3px -3px 0 0 ${colors.white};
  margin: 20px 80px 40px 20px;
  padding: 0.5rem;
  width: max-content;
  @media (min-width: ${breakpoints.md}px) {
    font-size: 5rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 80%;
  animation: ${Rise} 2s forwards;

  h3,
  h4 {
    margin-left: 20px;
  }

  @media (min-width: ${breakpoints.md}px) {
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2rem;
    }
  }
`;

const Title = styled.h3`
  /* font-size: ${dimensions.headingSizes.h3}px; */
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: ${colors.white};
`;

const NavLink = styled.h3`
  color: #fff;
  font-size: 1.8rem;
  font-family: "GT Sectra Regular";
  margin: 4rem 0;
  @media (min-width: ${breakpoints.md}px) {  
    font-size: 3rem;
  }
`;

const NotFound = (): JSX.Element => {
  useEffect(() => {
    document.title = "404 Not Found | Línte Na Farraige";
  });

  return (
    <React.Fragment>
      <div style={{ overflowY: "hidden" }}>
        <Navbar />
        <Heading>Not Found</Heading>

        <TextContainer>
          <Title> The page you are looking for does not exist</Title>

          <Link to="/">
            <NavLink>Home</NavLink>
          </Link>
        </TextContainer>

        {/* Content here */}
      </div>
    </React.Fragment>
  );
};

export default NotFound;
